jQuery(document).ready(function($){
    $('.form-step').click(function(e){
        e.preventDefault();
        if( !$(this).parent().hasClass('disabled') ){
            var navId = '#' + $(this).data('form');
            $(navId).submit();
        }
    });


    if ($('.form-validate .multi-list').length > 0) {
        $('.form-validate .multi-list').each(function(){
            var type = $(this).data('listtype');
            var element = (type == 'checkbox') ? 'input' : 'select' ;
            var childEl = (type == 'checkbox')  ? 'input' : 'option';
            var propName = (type == 'checkbox') ? 'checked' : 'selected';
            
            $(this).find(element).change(function(){
                var $parent = $(this).parents('.multi-list');
                var hasChecked = false;
                $parent.find(childEl).each(function(i,el){
                    if ((childEl == 'input' && this.checked) || (childEl == 'option' && this.selected)){
                        hasChecked = true;
                        return false;
                    }
                });
                if (hasChecked == false) {
                    $parent.find('.input-none').prop(propName, true);
                } else {
                    $parent.find('.input-none').prop(propName, false);
                }
            });
        });
        
        $('.form-validate .multi-list').each(function(){
            $(this).find('input, select').each(function(){
                $(this).trigger('change');
                return false;
            });
        });
    }


    // Bill me later checkbox
    if( $('input[name="bill_me_later"]').length > 0 ){
        $('input[name="bill_me_later"]').click(function(){
            if( $(this).is(':checked') ){
                //var cc_gadget = $(this).parents('gadget-credit_card');
                $('#cardholder_first_name').prop('disabled', true);
                $('#cardholder_last_name').prop('disabled', true);
                $('#payment-type').prop('disabled', true);
                $('#credit_card').prop('disabled', true);
                $('#csc').prop('disabled', true);
                $('#expdate').prop('disabled', true);
            }else{
                $('#cardholder_first_name').prop('disabled', false);
                $('#cardholder_last_name').prop('disabled', false);
                $('#payment-type').prop('disabled', false);
                $('#credit_card').prop('disabled', false);
                $('#csc').prop('disabled', false);
                $('#expdate').prop('disabled', false);
            }
        })
    }

    // Copy checkbox
    $('.input-copy').click(function(){
        var copy_from = '_' + $(this).data('copy-from');
        var copy_to = '_' + $(this).data('copy-to');

        if( copy_from == '_address' ){
            copy_from = '';
        }
        if( copy_to == '_address' ){
            copy_to = '';
        }

        if( $(this).is(':checked') ){
            $('input#first_name' + copy_to).val( $('input#first_name' + copy_from).val() );
            $('input#last_name' + copy_to).val( $('input#last_name' + copy_from).val() );
            $('input#address' + copy_to).val( $('input#address' + copy_from).val() );
            $('input#street' + copy_to).val( $('input#street' + copy_from).val() );
            $('input#city' + copy_to).val( $('input#city' + copy_from).val() );
            $('select#state' + copy_to).val( $('select#state' + copy_from).val() );
            $('input#zip_code' + copy_to).val( $('input#zip_code' + copy_from).val() );
            $('select#country' + copy_to).val( $('select#country' + copy_from).val() );
            $('input#email' + copy_to).val( $('input#email' + copy_from).val() );
            $('input#phone' + copy_to).val( $('input#phone' + copy_from).val() );
        }else{
            $(
                'input#first_name' + copy_to +
                ',input#last_name' + copy_to +
                ',input#address' + copy_to +
                ',input#street' + copy_to +
                ',input#city' + copy_to +
                ',select#state' + copy_to +
                ',input#zip_code' + copy_to +
                ',select#country' + copy_to +
                ',input#email' + copy_to +
                ',input#phone' + copy_to
            ).val('');
        }
    });

    // Validation
    if( $('.form-validate').length > 0 ){
        $('.form-validate').validate({
            errorPlacement: function(error, element){
                if ( $(element).hasClass('form-product') ){
                    error.appendTo( element.parents('.list-group').find('.product-error-container') );
                }else if( $(element).hasClass('form-quantity') ){
                    error.appendTo( element.parents('.col-sm-3') );
                }else{
                    error.appendTo( element.parents('.col-sm-9') );
                }
            },
            errorClass: 'has-error',
            validClass: 'has-success',
            highlight: function(element, errorClass, validClass){
                if ( $(element).hasClass('form-product') ){
                    $(element).parents('.list-group').find('.product-error-container').addClass('list-group-item-danger').removeClass('list-group-item-success');
                    $(element).parents('.list-group').find('.product-error-container').show();
                }else if( $(element).hasClass('form-quantity') ){
                    $(element).parents('.product-prices').addClass(errorClass).removeClass(validClass);
                }else{
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass){
                if ( $(element).hasClass('form-product') ){
                    $(element).parents('.list-group').find('.product-error-container').removeClass('list-group-item-danger').addClass('list-group-item-success');
                    $(element).parents('.list-group').find('.product-error-container').hide();
                }else if( $(element).hasClass('form-quantity') ){
                    $(element).parents('.product-prices').removeClass(errorClass).addClass(validClass);
                }else{
                    $(element).parents('.form-group').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    }

    // Datetimepicker Defaults
    var today = new Date();
    today.setHours(0,0,0,0);
    var tomorrow = new Date();
    tomorrow.setHours(23,59,59,999);

    // Loop through fields and apply rules
    $('.form-validate input, .form-validate textarea, .form-validate select, .form-validate .dpdate').each(function(){
        fieldName = $(this).attr('name');
        if( $(this).attr('data-count-type') && ( $(this).data('count-type') === 'words' || $(this).data('count-type') === 'characters' ) ){
            $(this).simplyCountable({
                counter: '.' + fieldName + '_counter',
                countType: $(this).data('count-type'),
                maxCount: $(this).data('count-limit'),
                strictMax: true,
                countDirection: 'down'
            });
        }
        if( $(this).hasClass('num') ){
            $(this).rules( 'add', {
                number: true
            });
            if( $(this).attr('data-max') && $(this).data('max') !== '' ){
                $(this).rules( 'add', {
                    max: $(this).data('max')
                });
            }
            if( $(this).attr('data-min') && $(this).data('min') !== '' ){
                $(this).rules( 'add', {
                    min: $(this).data('min')
                });
            }
        }
        if( $(this).attr('data-required-message') && $(this).data('required-message') !== '' ){
            $(this).rules( 'add', {
                required: true,
                messages: { required: $(this).data('required-message') }
            });
        }
        if( $(this).attr('data-min-choices') && $(this).data('min-choices') !== '' ){
            $(this).rules( 'add', {
                minlength: $(this).data('min-choices')
            });
            if( $(this).attr('data-minlength-message') && $(this).data('minlength-message') !== ''  ){
                $(this).rules( 'add', {
                    messages: { minlength: $(this).data('minlength-message') }
                });
            }
        }
        if( $(this).attr('data-max-choices') && $(this).data('max-choices') !== '' ){
            $(this).rules( 'add', {
                maxlength: $(this).data('max-choices')
            });
            if( $(this).attr('data-maxlength-message') && $(this).data('maxlength-message') !== ''  ){
                $(this).rules( 'add', {
                    messages: { maxlength: $(this).data('maxlength-message') }
                });
            }
        }
        if( $(this).attr('name') == 'tnpay-number' ){
            $(this).mask( '0000000000000000' );
        }
        if( $(this).attr('name') == 'tnpay-cvv' ){
            $(this).mask( '0009' );
        }
        if( $(this).attr('data-mask') && $(this).data('mask') !== '' ){
            $(this).mask( $(this).data('mask') );
        }
        if( $(this).attr('data-datetime') && $(this).data('datetime') !== '' ){
            if( $(this).data('datetime') === 'datetime' ){
                $(this).datetimepicker({
                    fontAwesome: true,
                    format: "MM dd, yyyy HH:ii P",
                    showMeridian: true,
                    autoclose: true,
                    startView: 'month',
                    minView: 'hour',
                    maxView: 'decade',
                    todayHighlight: true
                });
            }else if( $(this).data('datetime') === 'date' ){
                $(this).datetimepicker({
                    fontAwesome: true,
                    format: "MM dd, yyyy",
                    showMeridian: true,
                    autoclose: true,
                    startView: 'month',
                    minView: 'month',
                    maxView: 'decade',
                    todayHighlight: true
                });
            }else if( $(this).data('datetime') === 'time' ){
                $(this).datetimepicker({
                    fontAwesome: true,
                    format: "HH:ii P",
                    showMeridian: true,
                    autoclose: true,
                    startView: 'day',
                    minView: 'hour',
                    maxView: 'day',
                    todayHighlight: true,
                    startDate: today,
                    endDate: tomorrow
                });
            }
        }
    });
});